import React from 'react';

const DefaultLoadingScreen = () =>
    <div
        style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white'
        }}
    />;

export default DefaultLoadingScreen;
